// import React from 'react';
// import './MainPage.css';
// import MainPage from './MainPage';
import './App.css';
import goresa from './goresa.png';

function App() {
  return (
    // <react-fragment>
    //   <MainPage />
    // </react-fragment>
    <div className="body">
    <div className="body-title">
        <img src={goresa} alt="" className="images" />
    </div>
    </div>

  );
}

export default App;
